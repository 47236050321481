import { createApp } from 'vue/dist/vue.esm-bundler';
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap"
import VueChartkick from 'vue-chartkick'
import 'chartkick/chart.js'
import * as Turbo from '@hotwired/turbo'
Turbo.start()

document.addEventListener("turbo:load", function() {
  const app = createApp({
    data() {
      return {
        course: 'Vue on Rails',
        isUsersSubmenuExpanded: false,
        isTeamsSubmenuExpanded: false,
        isEventsSubmenuExpanded: false,
        isMicropostsSubmenuExpanded: false,
        isTagsSubmenuExpanded: false,
        isDashboardSubmenuExpanded: false,
        isTeamDiscarded: false,
        isAccountDiscarded: false,
        isMicropostDiscarded: false,
        isEventDiscarded: false
      }
    },
    mounted: function() {
      this.isDashboardSubmenuExpanded = localStorage.getItem('dashboardSubmenu') === 'expanded';
      this.isUsersSubmenuExpanded = localStorage.getItem('usersSubmenu') === 'expanded';
      this.isTeamsSubmenuExpanded = localStorage.getItem('teamsSubmenu') === 'expanded';
      this.isEventsSubmenuExpanded = localStorage.getItem('eventsSubmenu') === 'expanded';
      this.isMicropostsSubmenuExpanded = localStorage.getItem('micropostsSubmenu') === 'expanded';
      // 从服务器获取团队状态
      let teamId = document.querySelector('#team-status')?.dataset.teamId;
      console.log("teamId", teamId);
      if (teamId) {
        fetch('/api/teams/' + teamId + '/status') // 替换为你的 API 地址
          .then(response => response.json())
          .then(data => {
            this.isTeamDiscarded = data.isDiscarded;
          });
      }

      // 从服务器获取Account状态
      let accountId = document.querySelector('#account-status')?.dataset.accountId;
        console.log("accountId", accountId);
        if (accountId) {
          fetch('/api/accounts/' + accountId + '/status') // 替换为你的 API 地址
            .then(response => response.json())
            .then(data => {
              this.isAccountDiscarded = data.isDiscarded;
            });
        }

      // 从服务器获取Micropost状态
      let micropostId = document.querySelector('#micropost-status')?.dataset.micropostId;
        console.log("micropostId", micropostId);
        if (micropostId) {
          fetch('/api/microposts/' + micropostId + '/status') // 替换为你的 API 地址
            .then(response => response.json())
            .then(data => {
              this.isMicropostDiscarded = data.isDiscarded;
            });
        }

      // 从服务器获取Event状态
      let eventId = document.querySelector('#event-status')?.dataset.eventId;
      console.log("eventId", eventId);
      if (eventId) {
        fetch('/api/events/' + eventId + '/status') // 替换为你的 API 地址
          .then(response => response.json())
          .then(data => {
            this.isEventDiscarded = data.isDiscarded;
          });
      }


    },

    watch: {
      isDashboardSubmenuExpanded: function(newValue) {
        localStorage.setItem('dashboardSubmenu', newValue ? 'expanded' : 'collapsed');
      },
      isUsersSubmenuExpanded: function(newValue) {
        localStorage.setItem('usersSubmenu', newValue ? 'expanded' : 'collapsed');
      },
      isTeamsSubmenuExpanded: function(newValue) {
        localStorage.setItem('teamsSubmenu', newValue ? 'expanded' : 'collapsed');
      },
      isEventsSubmenuExpanded: function(newValue) {
        localStorage.setItem('eventsSubmenu', newValue ? 'expanded' : 'collapsed');
      },
      isMicropostsSubmenuExpanded: function(newValue) {
        localStorage.setItem('micropostsSubmenu', newValue ? 'expanded' : 'collapsed');
      }
    }
  })

  app.use(VueChartkick)
  app.mount('#app');
  console.log("app", app);
});

document.addEventListener('DOMContentLoaded', function () {
  var links = document.querySelectorAll('#usersSubmenu a');
  links.forEach(function (link) {
    link.addEventListener('click', function (event) {
      console.log("link", link);
      event.stopPropagation();
    });
  });
});



// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>


// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
